* {
  box-sizing: border-box;
}

:root {
  --color-1: #000;
  --color-2: white;
  --color-3: #0084ff;
  --color-4: grey;
  --color-5: #eb4034;
  --color-6: #333;
}

/* Navbar */
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0px 20px;
  color: wheat;
  width: fit-content;
}
nav a {
  color: var(--color-2);
  text-decoration: none;
}
nav div a {
  margin-right: 20px;
}

section {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 100px;
  box-shadow: 1px 2px 10px var(--color-4);
  padding: 10px 20px;
  border-radius: 5px;
  
}
section h3 {
  text-align: center;
  font-size: 20px;
  color: var(--color-4);
}
/* Register */
section .form {
  margin-top: 30px;
  padding: 0px 20px;
}
.input_container {
  margin-top: 20px;
}
.input_container input {
  width: 100%;
  padding: 10px;
  outline: none;
  margin-top: 10px;
  border: 1px solid var(--color-6);
  border-radius: 5px;
}
.btn_container {
  margin: 10px 0px;
  text-align: center;
}
.btn {
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--color-4);
  background: var(--color-1);
  color: var(--color-2);
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  font-size: 16px;
}
.btn:hover {
  transform: scale(1.05);
}
.error {
  text-align: center;
  color: var(--color-5);
}

/* Profile */
.profile_container {
  display: 'flex';
  align-items: 'center';
  color: #000;
  padding: 10%;
}
.img_container {
  position: relative;
  
}
.img_container img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid var(--color-4);
  transition: 0.5s ease-in-out all;
  position: relative;
  top: 35%;
  left: 35%;
}
.img_container:hover img {
  opacity: 0.4;
}
.img_container:hover .overlay {
  opacity: 1;
}
.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.text_container {
  flex-grow: 1;
}
.text_container h1 {
  text-align: center;
  color: var(--color-4);
}
.text_container p {
  text-align: center;
}

.home_container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  max-height: 40vh;
  overflow-x: none;
}
.users_container {
  margin-top: 10px;
  border: 2px solid var(--color-6);
  overflow-y: auto;
  padding: 5px;
}
.user_wrapper {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  overflow-y: auto;
  border-radius: 7px;
}
.user_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_detail {
  display: flex;
  align-items: center;
}
.user_detail h4 {
  margin-left: 10px;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #2E8359;
}
.user_status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.online {
  background: #34eb52;
}
.offline {
  background: var(--color-5);
}
.messages_container {
  position: relative;
  width: 100%;
}
.messages_user {
  padding: 10px;
  border-bottom: 2px solid var(--color-6);
}
.no_conv {
  font-size: 20px;
  color: var(--color-4);
  text-align: center;
  
}
.message_form {
  position: sticky;
  bottom: 0;
  left: 20%;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message_form input {
  width: 50vw;
  margin: 0px 10px 10px;
  padding: 10px;
  border-radius: 5px;
  outline: 1px solid #000;
  border: 1px solid #000;
  
}
.message_form .btn {
  margin-top: -10px;
  background: #2E8359;
}
.messages {
  height: 500px;
  overflow-y: auto;
  border-bottom: 1px solid var(--color-6);
  color: #000;
}
.message_wrapper {
  margin-top: 5px;
  padding: 0px 5px;
}
.message_wrapper img {
  width: 100%;
  border-radius: 5px;
}
.message_wrapper p {
  padding: 10px;
  display: inline-block;
  max-width: 100%;
  text-align: left;
  border-radius: 15px;
}
.message_wrapper small {
  display: inline-block;
  margin-top: 15px;
  opacity: 0.8;
}
.message_wrapper.own {
  text-align: right;
}
.me {
  background:#41c2de;
  color: black;
}
.friend {
  background: #2E8359;
  color: #000;
}
.selected_user {
  background: white;
  box-shadow: 0px 10px 6px #aaaaaa;
}
.truncate {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate strong {
  margin-right: 10px;
}
.unread {
  margin-left: 10px;
  background: var(--color-3);
  color: white;
  padding: 2px 4px;
  border-radius: 10px;
}
.sm_screen {
  display: none;
}
@media screen and (max-width: 767px) {
  .home_container {
    grid-template-columns: 2fr 3fr;
  }
  .message_form {
    left: 3%;
    right: 0;
    bottom: 5px;
  }
  .message_wrapper p {
    max-width: 75%;
  }
}

@media screen and (max-width: 576px) {
  .home_container {
    grid-template-columns: 1fr 5fr;
  }
  .user_wrapper {
    display: none;
  }
  .sm_container {
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
  }
  .sm_screen {
    display: inline-block;
  }
  .message_form input {
    width: 50vw;
    margin: 0px 10px;
  }
  .message_form .btn {
    margin: 0px;
  }

  .message_wrapper p {
    max-width: 100%;
  }
  
}
